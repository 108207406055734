import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
    
  <div class="pa-3">

      <uploadingDialog v-bind:uploadingDialog = "this.uploadingDialog"></uploadingDialog>
      <mySnackBar      v-bind:MySnackBar      = "this.MySnackBar"></mySnackBar>

      <v-container>
        <v-row class="pt-2 pa-1 mb-2 d-flex justify-space-between align-baseline">
              
           <div class="pa-0" >Self Owned Commercial</div>
            
            <div>   
              <v-btn  v-if = "this.$router.currentRoute.path != '/'"  @click="formReviewUpload" class="mx-2" fab dark small color="red darken-3">
                <v-icon dark>mdi-file-pdf</v-icon>
             </v-btn>
          
            <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
                <v-icon dark>mdi-presentation-play</v-icon>
            </v-btn>
            
            </div>

        </v-row>
      </v-container>

      <v-row dense justify="center">
         <v-expansion-panels class="expansion_panels">
      
              <!-- customerComponent -->
                  <customer v-bind:customer = "customer" />
              <!-- customerComponent -->

              <!-- Vessel Details-->
                  <v-expansion-panel class="expansion_pane mb-1"> 
                  <v-expansion-panel-header>Vessel Details</v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0">
                      <hr >
                      <v-container>
               
                      <v-row dense >
          
                            <v-col cols="12" sm="3">
                              <v-col cols="12">
                                 <v-radio-group class="mt-0" dense label = "Vessel Colour."  v-model  = "vessel.colour">
                                   <v-radio label="Green"            value="Green"></v-radio>
                                   <v-radio label="White"            value="White"></v-radio>
                                   <v-radio label="Other"            value="Other"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="12">
                                 <v-radio-group class="mt-0" dense label = "Vessel Use."  v-model  = "vessel.use">
                                   <v-radio label="Liquid"            value="liquid"></v-radio>
                                   <v-radio label="Vapour"            value="vapour"></v-radio>
                                </v-radio-group>
                              </v-col>

                            </v-col>

                            <v-col cols="12" sm="4">
                               <v-col cols="12">
                                  <v-text-field outlined dense v-model="vessel.gasContents" label="Gas Contents (%)"></v-text-field>
                               </v-col>

                               <v-col cols="12">
                                   <v-text-field outlined dense v-model="vessel.nominalPropaneCapacity" label="Nominal Propane Capacity (Litres)"></v-text-field>
                               </v-col>
                               
                            </v-col>

                      </v-row>
                    
                      </v-container>
                                           
                      </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Vessel Details-->

              <!-- Data Plate Details-->
                  <v-expansion-panel class="expansion_pane mb-1"> 
                  <v-expansion-panel-header>Data Plate Details</v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0">
                      <hr>

                      <v-container>

                          <v-row dense>
                                  <v-radio-group     v-model="dataPlate.plateCondition" label="Data Plate Condition ? "> 
                                    <v-radio label="Good"         value="good"></v-radio>
                                    <v-radio label="None"         value="none"></v-radio>
                                    <v-radio label="Rusted"       value="rusted"></v-radio>
                                    <v-radio label="Illegible"    value="illegible"></v-radio>
                                  </v-radio-group>
                          </v-row>
 

                             
                      <v-row dense>
                         <v-col cols="12" sm="6">  
                            <v-text-field outlined dense v-model="dataPlate.manufacturer" label="Manufacturer"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">  
                            <v-text-field outlined dense v-model="dataPlate.manufacturerSerialNo" label="Manufacturers Serial Number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                         <v-col cols="12" sm="6">  
                            <v-text-field outlined dense v-model="dataPlate.designCode" label="Design Code"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">  
                            <v-text-field outlined dense v-model="dataPlate.inspectionAuthority" label="Inspection Authority"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                         <v-col cols="12" sm="4">  
                                      <DateSelector
                                         caption           =  "Original Test Date"
                                        v-bind:enabled     =  "true"
                                        v-bind:curDate     =  "dataPlate.originalTestDate"
                                        @updated           = "(e) => { dataPlate.originalTestDate = e}"
                                     ></DateSelector>
                        </v-col>
                        <v-col cols="12" sm="4">  
                                      <DateSelector
                                         caption           =  "Latest Test Date"
                                        v-bind:enabled     =  "true"
                                        v-bind:curDate     =  "dataPlate.latestTestDate"
                                        @updated           = "(e) => { dataPlate.latestTestDate = e}"
                                     ></DateSelector>
                        </v-col>
                  
                        <v-col cols="12" sm="4">  
                             <v-text-field outlined dense v-model="dataPlate.waterCapacity" label="Water Capacity (Litres)"></v-text-field>
                        </v-col>
                      </v-row>
                     
                      <v-row dense>
                         <v-col cols="12" sm="6">  
                             <v-text-field outlined dense v-model="dataPlate.designPressureMax" label="Design Pressure (Bar g) Max"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">  
                             <v-text-field outlined dense v-model="dataPlate.designPressureMin" label="Design Pressure (Bar g) Min (Vacuum)"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                         <v-col cols="12" sm="6">  
                             <v-text-field outlined dense v-model="dataPlate.designTemperatureMax" label="Design Temperature (°C) Max"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">  
                             <v-text-field outlined dense v-model="dataPlate.designTemperatureMin" label="Design Temperature (°C) Min"></v-text-field>
                        </v-col>
                      </v-row>

                    
                      </v-container>
                      
                     
                      </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Data Plate Details-->
 
              <!-- Valve Details -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                    <v-expansion-panel-header>Valve Details</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <hr>

                      <v-container fluid>

                        <v-row dense v-for="(item, index) in valveDetails" :key="index">
                         
                            <v-col cols="12">
                                  <span dense><strong>REF : {{item.ref}}</strong></span>
                            </v-col>

                             <v-col cols="12">
                                  <span dense><strong>VALVE : </strong> {{item.text}}</span>
                            </v-col>
                                                     
                            <v-col v-if="['A','B','C','D','E','F','G','H','I'].includes(item.ref)" cols="12" sm="3">
                                   <v-text-field  outlined dense  v-model="item.manufacturer" label="Manufacturer"></v-text-field>
                            </v-col>
                     
                            <v-col v-if="['A','B','F','G','H','I'].includes(item.ref)" cols="12" sm="3">
                                  <v-text-field  outlined dense  v-model="item.typeNo" label="Type No"></v-text-field>
                            </v-col>

                            <v-col v-if="['C','D','E'].includes(item.ref)" cols="12" sm="2">
                                  <v-text-field  outlined dense  v-model="item.typeNo" label="Type No"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3">
                                     <DateSelector
                                        caption            =  "Date of Manufacture"
                                        v-bind:enabled     =  "true"
                                        v-bind:curDate     =  "item.dateOfManufacture"
                                        @updated           = "(e) => { item.dateOfManufacture = e}"
                                     ></DateSelector>
                            </v-col>
                  
                            <v-col v-if="['A','B','F','G','H','I','J'].includes(item.ref)" cols="12" sm="3">
                                  <v-text-field  outlined dense  v-model="item.frequencyOfExamination" label="Frequency of Examination (months)"></v-text-field>
                            </v-col>

                            <v-col v-if="['C'].includes(item.ref)" cols="12" sm="2">
                                   <v-text-field  outlined dense  v-model="item.checkLockFrequencyOfExamination"   label="Check Lock Frequency of Examination (months)"></v-text-field>
                            </v-col>

                            <v-col v-if="['C'].includes(item.ref)" cols="12" sm="2">
                                   <v-text-field  outlined dense  v-model="item.manualValveFrequencyOfExamination" label="Manual Valve Frequency of Examination (months)"></v-text-field>
                            </v-col>

                            <v-col v-if="['D','E'].includes(item.ref)" cols="12" sm="2">
                                   <v-text-field  outlined dense  v-model="item.checkLockFrequencyOfExamination"    label="Check Lock Frequency of Examination (months)"></v-text-field>
                            </v-col>
                            
                            <v-col v-if="['D','E'].includes(item.ref)" cols="12" sm="2">
                                   <v-text-field  outlined dense  v-model="item.serviceValveFrequencyOfExamination" label="Service Valve Frequency of Examination (months)"></v-text-field>
                            </v-col>


                        </v-row>

                      </v-container>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Valve Details -->

              <!-- Relief Valve Details -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                  <v-expansion-panel-header dense >Relief Valve Details</v-expansion-panel-header>
                     <v-expansion-panel-content class="pa-0">
                      <hr>

                      <v-container>

                        <v-row dense>
                          <v-col cols="12" sm="6">  <v-text-field outlined dense v-model="reliefValve.manufacturer" label="Manufacturer"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">  
                                          <DateSelector
                                          caption           =  "Date of Manufacturer"
                                          v-bind:enabled     =  "true"
                                          v-bind:curDate     =  "reliefValve.dateOfManufacture"
                                          @updated           = "(e) => { reliefValve.dateOfManufacture = e}"
                                      ></DateSelector>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="6">  
                              <v-text-field outlined dense v-model="reliefValve.reconditioner" label="Reconditioner"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">  
                                        <DateSelector
                                          caption           =  "Date of Reconditioning"
                                          v-bind:enabled     =  "true"
                                          v-bind:curDate     =  "reliefValve.dateOfReconditioning"
                                          @updated           = "(e) => { reliefValve.dateOfReconditioning = e}"
                                      ></DateSelector>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="2">  
                              <v-text-field outlined dense v-model="reliefValve.typeNo" label="Type No"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8">  
                              <v-text-field outlined dense v-model="reliefValve.noOfExposedThreads" label="No of Exposed Threads between RV and Adpater"></v-text-field>
                          </v-col>
                         <v-col cols="12" sm="2">  
                              <v-text-field outlined dense v-model="reliefValve.setPressure" label="Set Pressure"></v-text-field>
                          </v-col>
 

                        </v-row>


                        <v-row dense >
                              <v-col cols="12">
                                <v-textarea outlined v-model="reliefValve.pipeworkVisualInspectionComments" label="Pipework Visual Inspection comments:"> </v-textarea>
                              </v-col>
                        </v-row>
                        

                      </v-container>
                     
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Relief Valve Details -->

              <!-- Site Details-->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                   <v-expansion-panel-header dense >Site Details</v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <hr>
                      <v-container>

                            <v-row dense >
                                <v-col cols="12">
                                  <v-radio-group     v-model="siteDetails.doesSiteConformWithRelevantCOP" label="Does Site Conform with relevant COP ? "> 
                                   <v-radio label="Yes"      value="yes"></v-radio>
                                   <v-radio label="No"       value="no"></v-radio>
                                  </v-radio-group>
                                </v-col>
                            </v-row>

                            <v-row v-if = "siteDetails.doesSiteConformWithRelevantCOP == 'no'" dense >
                              <v-col cols="12">
                                 <v-radio-group dense label = "if 'NO' tick fault or specify below."  v-model  = "siteDetails.fault">
                                   <v-radio label="Separation Distance"     value="separationDistance"></v-radio>
                                   <v-radio label="Hazards"                 value="hazards"></v-radio>
                                   <v-radio label="Vegetation/rubbish"      value="vegetationRubbish"></v-radio>
                                   <v-radio label="Line Of Sight"           value="lineOfSight"></v-radio>
                                </v-radio-group>
                              </v-col>
                          
                              <v-col cols="12">
                                  <v-textarea outlined v-model="siteDetails.otherFault" label="Specify Other Faults:"> </v-textarea>
                              </v-col>
                              
                            </v-row>
                            

                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!--  Site Details-->

               <!-- last one for space-->
               <v-expansion-panel class="expansion_panel mb-1"> 
               </v-expansion-panel>
               <!-- last one for space-->
         

         </v-expansion-panels>
      </v-row>

  </div>

</template>

<script>

import {store} from '../../main.js'
import Localbase from 'localbase'


export default {
    name      : "selfOwnedCommercial",
    formName  : "selfOwnedCommercial",
    computed  : {},
    methods   : {

                saveDocument( customer, documentKey, documentObject ){
                      let db     = new Localbase( this.$dbName );
                      db.collection( customer ).doc(documentKey).set( documentObject )
                      .then(response => {
                        console.log( response)
                      })
                      .catch(error => {
                        console.log( error)
                      })
                },

                retrieveDocument( customer, documentKey ){

                    let db = new Localbase(  this.$dbName );
                    db.collection(customer).doc(documentKey).get()
                    .then( lastestObject => {
                      if ( lastestObject != null ) {
                        this.dataPlate            = lastestObject.formReviewData.dataPlate;
                        this.valveDetails         = lastestObject.formReviewData.valveDetails;
                        this.reliefValve          = lastestObject.formReviewData.reliefValve;
                        this.siteDetails          = lastestObject.formReviewData.siteDetails;
                        this.vessel               = lastestObject.formReviewData.vessel;
                      }
                    })
                    .catch( error => {
                      console.log( error)
                    });

                },

                showSnackBar( message = "You need to review the form first..."){
                    this.MySnackBar.text          =  message;
                    this.MySnackBar.showImage     = "icon";
                    this.MySnackBar.show          = true;
                    setTimeout( () => { this.MySnackBar.show  = false }, 1000)
                },
                showUploadingDialog( message = "Uploading PDF...") {
                         this.uploadingDialog.message    = message;
                         this.uploadingDialog.showDialog = true;
                },
                closeUploadingDialog( message = "PDF Uploaded...") {
                          this.uploadingDialog.message    = message;
                          setTimeout( () => {
                                    this.uploadingDialog.message    = "";
                                    this.uploadingDialog.showDialog = false;
                                 }, 2000)
                },
                generateSaveObject() {
                          let saveObject   =  { 
                                                     "formReviewName"    : "selfOwnedCommercial",
                                                     "formReviewURL"     :  this.$restServer.selfOwnedCommercialURL,
                                                     "formReviewData"    :  {  
                                                                            "requestedResponse"           : "pdf",

                                                                            "customer"                    :  this.customer,
                                                                            "salesman"                    :  this.salesman,
                                                                            "engineer"                    :  this.engineer,

                                                                            "dataPlate"                   :  this.dataPlate,
                                                                            "valveDetails"                :  this.valveDetails,
                                                                            "reliefValve"                 :  this.reliefValve,
                                                                            "siteDetails"                 :  this.siteDetails,
                                                                            "vessel"                      :  this.vessel,
                                                                          } 
                                                    };
                          return saveObject;                          

                },
                generateLoggingObject() {
                     return  { 
                               "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                               "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                               "formName"    : this.formName,
                             };
                }, 
                formReviewUpload() {

                       const url                        =  this.$restServer.uploadPDFToDropBoxURL;
                       var   formReviewReponse          =  store.retrieveFormReviewResponse();
              
                       if ( formReviewReponse  ) {

                              let   self        = this;
                          
                              formReviewReponse['loggingObject']     = this.generateLoggingObject();
                              this.showUploadingDialog();
                              
                              this.$http.post( url, formReviewReponse ) 
                              .then(  (response) => {
                                   store.resetFormReviewResponse();
                                   self.closeUploadingDialog();
                              })
                              .catch( (e)        => { 
                                   localStorage.removeItem("equalResponsePdfReviewObject");
                                   self.closeUploadingDialog(e.message);
                                   console.log(e);
                              });
  
                       } else {
                            this.showSnackBar("You need to review the form first...");
                       }

                },
                formReview(e){
                    try {
                          var saveObject  =  this.generateSaveObject();
                          store.saveFormObject("selfOwnedCommercial", saveObject)
                          this.$router.push( { name : "FormReview", "params" : saveObject } );

                    } catch (e) {
                       alert(e);
                    }
                },

    },
    data() {
      return {
         MySnackBar            :  { "show" : false, "text" : "Default Text", "showImage" : "icon"},
         uploadingDialog       :  { "showDialog" : false, "message": "" },
         formName              : "selfOwnedCommercial",
         customer              :  {},
         salesman              :  {} ,
         engineer              :  {} ,
         dataPlate             :  {
              "plateCondition"        : "good",
              "manufacturer"          : "",
              "designCode"            : "",
              "manufacturerSerialNo"  : "",
              "originalTestDate"      : "",
              "inspectionAuthority"   : "",
              "latestTestDate"        : "",
              "waterCapacity"         : "",
              "designPressureMax"     : "",
              "designPressureMin"     : "",
              "designTemperatureMin"  : "",
              "designTemperatureMax"  : "",
         },
         valveDetails          : {
            "valveDetails_A"              : {"ref":"A","text" : "Filler Valve Manual Shut Off",                                 "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_B"              : {"ref":"B","text" : "Filler Valve Without Manual Shut off (Check Lock)",            "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_C"              : {"ref":"C","text" : "Filler Valve Manual Shut Off On Conversion",                   "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "manualValveFrequencyOfExamination" : "",  "checkLockFrequencyOfExamination": ""},
            "valveDetails_D"              : {"ref":"D","text" : "LTO Valve Top Mounted",                                        "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "serviceValveFrequencyOfExamination": "",  "checkLockFrequencyOfExamination": ""},
            "valveDetails_E"              : {"ref":"E","text" : "LTO Valve Bottom Mounted",                                     "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "serviceValveFrequencyOfExamination": "",  "checkLockFrequencyOfExamination": ""},
            "valveDetails_F"              : {"ref":"F","text" : "Vapour Valve",                                                 "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_G"              : {"ref":"G","text" : "Float Gauge",                                                  "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_H"              : {"ref":"H","text" : "Relief Valve Adapter",                                         "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_I"              : {"ref":"I","text" : "No RVA",                                                       "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
            "valveDetails_J"              : {"ref":"J","text" : "ECV Available/Identified with Emergency Number",               "manufacturer": "", "typeNo": "", "dateOfManufacture" : "", "frequencyOfExamination":""},
         },
         reliefValve           : {
              "manufacturer"          : "",
              "dateOfManufacture"     : "",
              "reconditioner"         : "",
              "dateOfReconditioning"  : "",
              "noOfExposedThreads"    : "",
              "typeNo"                : "",
              "setPressure"           : "",
              "pipeworkVisualInspectionComments" : "",          
         },
         siteDetails           : {
                  "doesSiteConformWithRelevantCOP" : "yes",
                  "fault"                          : "",
                  "otherFault"                     : ""
         },
         vessel                : {
              "colour"         : "",
              "use"            : "",
              "gasContents"    : "",
              "nominalPropaneCapacity" : "" 
         },
      };
    },
    created    (){

        store.consoleLog( "selfOwnedCommercial created");

        this.customer         = JSON.parse( localStorage.getItem("equalResponseCustomer") );
        this.salesman         = JSON.parse( localStorage.getItem("equalResponseSalesman") );
        this.engineer         = JSON.parse( localStorage.getItem("equalResponseEngineer") );

        //  reload the last selfOwnedCommercial if any         //
        const customer           = this.customer['ACCOUNT'];
        const documentKey        = this.$options.formName;
        this.retrieveDocument( customer, documentKey);

        /*
        var lastestObject     = store.retrieveFormObject("selfOwnedCommercial");
        if ( lastestObject != null ) {
             this.dataPlate            = lastestObject.formReviewData.dataPlate;
             this.valveDetails         = lastestObject.formReviewData.valveDetails;
             this.reliefValve          = lastestObject.formReviewData.reliefValve;
             this.siteDetails          = lastestObject.formReviewData.siteDetails;
             this.vessel               = lastestObject.formReviewData.vessel;
             
        }
        */
  
    },
    destroyed  () {

           store.consoleLog( "selfOwnedCommercial destroyed");
           let saveObject           =  this.generateSaveObject();
           const customer           =  this.customer['ACCOUNT'];
           const documentKey        =  this.$options.formName;
           this.saveDocument( customer, documentKey, saveObject);
           
           /* store.saveFormObject("selfOwnedCommercial", saveObject) */
    },
  
};
</script>

<style>

legend.v-label {
    font-size: small !important;
    font-weight: bold !important;
}

.a1{
  padding-left: 0 !important;
}

.a2{
  padding: 0 !important;
}

.parent {
    display: flex;
    flex-wrap: wrap;
}

.des {
    font-size: small !important;
}

.bold {
    font-size: small !important;
    font-weight: bold;
}



.v-expansion-panel-content__wrap{
  padding: 0 6px 6px;
}

.nowrap-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.diagram-wrapper {

    padding: 10px;
    background: #fff;
    display: inline-block;
    margin-bottom: 10px;
}

.diagram-wrapper-img { 
   max-width: 100%;
    height: auto;
}

.vue-js-switch#changed-font {
  font-size: 16px;
}

.passfailvessel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>
